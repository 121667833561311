import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { ROUTER_HOME } from "../App";
import Summary from "./Summary";

interface OrderForm {
  name: string;
  surname: string;
  email: string;
  address: string;
  zipcode: string;
  phone: string;
}

const orderFormDefault: OrderForm = {
  name: "",
  surname: "",
  email: "",
  address: "",
  zipcode: "",
  phone: "",
};

// nie zrobilem walidacji bo nie pamietam jak zrobic na zipcode.

const OrderDetails: React.FC = () => {
  const [orderForm, orderFormChange] = useState<OrderForm>(orderFormDefault);

  function setField(fieldName: keyof OrderForm, value: string) {
    orderFormChange((p) => ({ ...p, [fieldName]: value }));
  }

  function fieldProps(fieldName: keyof OrderForm) {
    return {
      value: orderForm[fieldName],
      onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
        setField(fieldName, e.target.value),
    };
  }

  //mam nadzieje ze to ma jakis sens bo troche na czuja

  return (
    <div className="orderdetails">
      <div className="orderdetails__center">
        <div className="orderdetails__summary">
          <Summary />
        </div>
        <div className="orderdetails-columns">
          <div className="orderdetails-columns-wrapper">
            <div className="orderdetails-columns__column">
              <div className="orderdetails-columns__column-buttons">
                <div className="orderdetails-columns__column-buttons-button">
                  <button className="button choose">Firma</button>
                </div>
                <div className="orderdetails-columns__column-buttons-button">
                  <button className="button choose">os. prywatna</button>
                </div>
              </div>
              <div className="orderdetails-columns__column-cell">
                <div className="orderdetails-columns__column-cell__header">
                  Imię
                </div>
                <input
                  className="orderdetails-columns__column-cell-input"
                  placeholder=""
                  {...fieldProps("name")}
                />
              </div>
              <div className="orderdetails-columns__column-cell">
                <div className="orderdetails-columns__column-cell__header">
                  Nazwisko
                </div>
                <input
                  className="orderdetails-columns__column-cell-input"
                  placeholder=""
                  {...fieldProps("surname")}
                />
              </div>
              <div className="orderdetails-columns__column-cell--double">
                <div className="orderdetails-columns__column-cell-small">
                  <div className="orderdetails-columns__column-cell__header">
                    Kod pocztowy
                  </div>

                  <input
                    className="orderdetails-columns__column-cell-input"
                    placeholder=""
                    {...fieldProps("zipcode")}
                  />
                </div>
                <div className="orderdetails-columns__column-cell-big">
                  <div className="orderdetails-columns__column-cell__header">
                    Adres zamieszkania
                  </div>
                  <input
                    className="orderdetails-columns__column-cell-input"
                    placeholder=""
                    {...fieldProps("address")}
                  />
                </div>
              </div>

              <div className="orderdetails-columns__column-cell">
                <div className="orderdetails-columns__column-cell__header">
                  Numer telefonu
                </div>
                <input
                  className="orderdetails-columns__column-cell-input"
                  placeholder=""
                  {...fieldProps("phone")}
                />
              </div>
              <div className="orderdetails-columns__column-cell">
                <div className="orderdetails-columns__column-cell__header">
                  Adres email
                </div>
                <input
                  className="orderdetails-columns__column-cell-input"
                  placeholder=""
                  {...fieldProps("email")}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="orderdetails__buttons">
          <div className="orderdetails__buttons-button">
            <NavLink to={ROUTER_HOME.BuyNow} className="button light">
              Powrót do zamówienia
            </NavLink>
          </div>
          <div className="orderdetails__buttons-button">
            <NavLink to={ROUTER_HOME.Payment} className="button">
              Przejdź do płatności
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
