import React, { useState } from "react";
import preview1 from "../assets/previews/preview1.png";
import preview2 from "../assets/previews/preview2.png";
import preview3 from "../assets/previews/preview3.png";
import preview4 from "../assets/previews/preview4.png";
import preview5 from "../assets/previews/preview5.png";
import preview6 from "../assets/previews/preview6.png";
import preview7 from "../assets/previews/preview7.png";
import preview8 from "../assets/previews/preview8.png";
import preview9 from "../assets/previews/preview9.png";
import preview10 from "../assets/previews/preview10.png";
import logo from "../assets/LIO-Logos-SE.png";
import { NavLink } from "react-router-dom";
import { ROUTER_HOME } from "../App";

const photos: string[] = [
  "/assets/previews/PIE_1.png",
  "/assets/previews/PIE_2.png",
  "/assets/previews/PIE_3.png",
  "/assets/previews/PIE_4.png",
  "/assets/previews/PIE_5.png",
  "/assets/previews/PIE_6.png",
  "/assets/previews/PIE_7.png",
  "/assets/previews/PIE_8.png",
  "/assets/previews/PIE_9.png",
  "/assets/previews/PIE_10.png",
];

const Preview: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState<number | undefined>();

  return (
    <div className="preview" id="podgląd">
      {currentIndex !== undefined ? (
        <div className="preview__player">
          <div className="preview__photo">
            <img
              src={photos[currentIndex]}
              alt=""
              className="preview__photo-image"
            />
          </div>
          <div
            className="preview__close"
            onClick={() => setCurrentIndex(undefined)}
          >
            close
          </div>

          <div className="preview__button left" onClick={() => movePhoto(-1)}>
            keyboard_arrow_left
          </div>
          <div className="preview__button right" onClick={() => movePhoto(1)}>
            keyboard_arrow_right
          </div>
        </div>
      ) : null}
      <div className="preview__center">
        <div className="preview__header">Zajrzyj do środka</div>
        <div className="preview__subheader">
          Zapoznaj się ze spisem treści oraz merytoryką poradnika. Kliknij na
          obraz, żeby powiększyć.
        </div>
        <div className="preview-columns">
          {photos.map((photo, index) => (
            <div
              className="preview-columns__cell"
              key={index}
              onClick={() => openPhoto(index)}
            >
              <div className="preview-columns__cell-wrapper">
                <img src={photo} className="preview-columns__cell-photo" />
              </div>
            </div>
          ))}
        </div>
        <div className="preview__buttons">
          <NavLink to={ROUTER_HOME.BuyNow} className="button secondary">
            Kup teraz <i className="start__buttons-icon">shopping_cart</i>
          </NavLink>
        </div>
        <div className="preview__bottomline-small"></div>
        <div className="preview__bottomcontent">
          <div className="preview__bottomcontent-header">
            Jesteś projektantem?
          </div>
          <div className="preview__bottomcontent-description">
            Zarejestruj się na mySchneider Partner Portal, by zakupić drukowany
            egzemplarz Poradnika Inżyniera Elektryka lub odebrać inne atrakcyjne
            nagrody za Punkty Aktywności. Uzyskasz również dostęp do streszczeń
            wielu rozdziałów poradnika, jak i dedykowanego zestawienia
            oprogramowania dla projektantów. Wykonuj bonusowane czynności,
            zbieraj punkty, zamawiaj nagrody oraz ciesz się dostępem do
            szerokiej bazy wiedzy na portalu!
          </div>
          <div className="preview__bottomcontent-buttons">
            <a
              href="https://se.com/myschneider"
              target="blank"
              className="button"
            >
              Zarejestruj się
            </a>
          </div>
        </div>
        <div className="preview__bottomline-big"></div>
        <div className="footer">
          <div className="footer-column">
            <div className="footer-column__cell">
              <div className="footer-column__cell-header">
                Chcesz dowiedzieć się więcej?
              </div>
              <div className="footer-column__cell-description">
                Zajrzyj na naszą stronę internetową, poświęconą Poradnikowi.
              </div>
              <div className="footer-column__cell-buttons">
                <a
                  href="https://www.se.com/pl/pl/work/products/product-launch/electrical-installation-guide/"
                  className="button light"
                  target="blank"
                >
                  Przejdź do strony
                </a>
              </div>
            </div>
            <div className="footer-column__cell">
              <div className="footer-column__cell-header">
                Masz pytania lub wątpliwości?
              </div>
              <div className="footer-column__cell-description">
                Skontaktuj się z Centrum Obsługi Klienta Schneider Electric
                Polska.
              </div>
              <div className="footer-column__cell-buttons">
                <a
                  href="https://www.se.com/pl/pl/work/support/customer-care/contact-schneider-electric.jsp"
                  className="button light"
                  target="blank"
                >
                  Przejdź do strony
                </a>
              </div>
            </div>
          </div>
          <div className="footer__logo-wrapper">
            <a
              className="footer__logo"
              href="https://www.se.com/pl/pl/"
              target="blank"
            >
              <img src={logo} className="footer__logo-image" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );

  function openPhoto(index: number) {
    setCurrentIndex(index);
  }

  function movePhoto(direction: -1 | 1) {
    let newIndex = (currentIndex || 0) + direction;
    newIndex =
      newIndex < 0
        ? photos.length - 1
        : newIndex >= photos.length
        ? 0
        : newIndex;
    setCurrentIndex(newIndex);
  }
};

export default Preview;
