import React from "react";
import { NavLink } from "react-router-dom";
import { ROUTER_HOME } from "../App";
import start_image from "../assets/image_start.jpg";
import Nav from "./Nav";

const BuyNow: React.FC = () => {
  return (
    <div className="buynow">
      <div className="buynow__center">
        <div className="buynow-columns">
          <div className="buynow-columns__left">
            <img src={start_image} className="buynow-columns__left-photo" />
          </div>
          <div className="buynow-columns__right">
            <div className="buynow-columns__right-wrapper">
              <div className="buynow-columns__right-header">
                Poradnik Inżyniera Elektryka
              </div>
              <div className="buynow-columns__right-table">
                <div className="buynow-columns__right-table__cell">
                  <div className="buynow-columns__right-table__cell-header">
                    Cena
                  </div>
                  <div className="buynow-columns__right-table__cell-content">
                    65 zł
                  </div>
                </div>
                <div className="buynow-columns__right-table__cell">
                  <div className="buynow-columns__right-table__cell-header">
                    Ilość
                  </div>
                  <div className="buynow-columns__right-table__cell-content add">
                    <i className="buynow-columns__right-table__cell-content__icon-right">
                      add
                    </i>{" "}
                    32{" "}
                    <i className="buynow-columns__right-table__cell-content__icon-left">
                      remove
                    </i>
                  </div>
                </div>
                <div className="buynow-columns__right-table__cell">
                  <div className="buynow-columns__right-table__cell-header">
                    Koszt
                  </div>
                  <div className="buynow-columns__right-table__cell-content">
                    145 zł
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="buynow-delivery">
          <div className="buynow-delivery-wrapper">
            <div className="buynow-delivery__icon">local_shipping</div>
            <div className="buynow-delivery__box">
              <div className="buynow-delivery__box-header">Koszt wysyłki</div>
              <div className="buynow-delivery__box-content">15 zł</div>
            </div>
          </div>
        </div>
        <div className="buynow-price">
          <div className="buynow-price__wrapper">Suma: 160 zł</div>
        </div>
        <div className="buynow-buttons">
          <div className="buynow-buttons__button">
            <NavLink to={ROUTER_HOME.OrderDetails} className="button">
              Przejdź dalej
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuyNow;
