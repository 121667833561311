import React from "react";
import { NavLink } from "react-router-dom";
import { ROUTER_HOME } from "../App";
import Nav from "./Nav";
import Summary from "./Summary";

const Payment: React.FC = () => {
  return (
    <div className="orderdetails">
      <div className="orderdetails__center">
        <div className="orderdetails__summary">
          <Summary />
        </div>
        <div className="orderdetails-columns">
          <div className="orderdetails-columns-wrapper">
            <div className="orderdetails-columns__column">
              <div className="orderdetails-columns__column-cell">
                <div className="orderdetails-columns__column-cell__header">
                  Imię
                </div>
                <div className="orderdetails-columns__column-cell__content">
                  Karol
                </div>
              </div>
              <div className="orderdetails-columns__column-cell">
                <div className="orderdetails-columns__column-cell__header">
                  Nazwisko
                </div>
                <div className="orderdetails-columns__column-cell__content">
                  Karolowski
                </div>
              </div>
              <div className="orderdetails-columns__column-cell--double">
                <div className="orderdetails-columns__column-cell-small">
                  <div className="orderdetails-columns__column-cell__header">
                    Kod pocztowy
                  </div>
                  <div className="orderdetails-columns__column-cell__content">
                    00-000
                  </div>
                </div>
                <div className="orderdetails-columns__column-cell-big">
                  <div className="orderdetails-columns__column-cell__header">
                    Adres zamieszkania
                  </div>
                  <div className="orderdetails-columns__column-cell__content">
                    Kolorowa 123
                  </div>
                </div>
              </div>

              <div className="orderdetails-columns__column-cell">
                <div className="orderdetails-columns__column-cell__header">
                  Numer telefonu
                </div>
                <div className="orderdetails-columns__column-cell__content">
                  123456789
                </div>
              </div>
              <div className="orderdetails-columns__column-cell">
                <div className="orderdetails-columns__column-cell__header">
                  Adres email
                </div>
                <div className="orderdetails-columns__column-cell__content">
                  adres@email.com
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="orderdetails__buttons">
          <div className="orderdetails__buttons-button">
            <NavLink to={ROUTER_HOME.OrderDetails} className="button light">
              powrót do danych
            </NavLink>
          </div>
          <div className="orderdetails__buttons-button">
            <button className="button">ZAMAWIAM</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payment;
