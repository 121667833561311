import React from "react";
import image_start from "../assets/image_start.jpg";

const Summary: React.FC = () => {
    return (
        <div className="summary">
            <div className="summary__center">
                <div className="summary__order">
                    <div className="summary__order-info">
                        <div className="summary__header">
                            Podsumowanie zamówienia:
                        </div>
                        <div className="summary__order-info-wrapper">
                            <div className="buynow-columns">
                                <div className="buynow-columns__left">
                                    <img
                                        src={image_start}
                                        className="buynow-columns__left-photo"
                                    />
                                </div>
                                <div className="buynow-columns__right">
                                    <div className="buynow-columns__right-wrapper">
                                        <div className="buynow-columns__right-header">
                                            Poradnik Inżyniera Elektryka
                                        </div>
                                        <div className="buynow-columns__right-table">
                                            <div className="buynow-columns__right-table__cell">
                                                <div className="buynow-columns__right-table__cell-header">
                                                    Cena
                                                </div>
                                                <div className="buynow-columns__right-table__cell-content">
                                                    65 zł
                                                </div>
                                            </div>
                                            <div className="buynow-columns__right-table__cell">
                                                <div className="buynow-columns__right-table__cell-header">
                                                    Ilość
                                                </div>
                                                <div className="buynow-columns__right-table__cell-content">
                                                    32
                                                </div>
                                            </div>
                                            <div className="buynow-columns__right-table__cell">
                                                <div className="buynow-columns__right-table__cell-header">
                                                    Koszt
                                                </div>
                                                <div className="buynow-columns__right-table__cell-content">
                                                    145 zł
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="summary__order-info__cell">
                                <div className="summary__order-info__header">
                                    Koszt wysyłki:
                                </div>
                                <div className="summary__order-info__content">
                                    15 zł
                                </div>
                            </div>
                            <div className="summary__order-info__cell">
                                <div className="summary__order-info__header">
                                    Razem:
                                </div>
                                <div className="summary__order-info__content">
                                    145 zł
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Summary;
