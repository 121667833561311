import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { ROUTER_HOME } from "../App";
import logo from "../assets/LIO-Logos-SE.png";

const Nav: React.FC = () => {
  const [isOpen, isOpenChange] = useState(false);

  function changeState() {
    isOpenChange((p) => !p);
  }

  function closeNav() {
    isOpenChange(false);
  }

  return (
    <div className={`nav ${isOpen ? "is-open" : ""}`}>
      <div className="nav__center">
        <NavLink to={ROUTER_HOME.MainPage} className="nav__logo">
          <img src={logo} className="nav__logo-image" />
        </NavLink>
        <div className="nav__right">
          <div className="nav__right-button" onClick={() => changeState()}>
            {isOpen ? "close" : "notes"}
          </div>
        </div>
        <div className="nav__links">
          <div className="nav__links-left">
            <NavLink to={ROUTER_HOME.About} className="nav__link">
              O poradniku
            </NavLink>
            <NavLink to={ROUTER_HOME.Authors} className="nav__link">
              O autorach
            </NavLink>
            <NavLink to={ROUTER_HOME.Preview} className="nav__link">
              Podgląd
            </NavLink>
            <div className="nav__link">
              <NavLink to={ROUTER_HOME.BuyNow} className="button primary">
                Zakup Poradnik
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Nav;
