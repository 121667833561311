import React, { useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { ROUTER_HOME } from "../App";
import { goToStartSection } from "../utils/GoToStartSection";
import About from "./About";
import Authors from "./Authors";
import Preview from "./Preview";
import Start from "./Start";

const MainPage: React.FC = () => {
    const location = useLocation();

    useEffect(() => {
        switch (location.pathname) {
            case ROUTER_HOME.About:
                goToStartSection("oporadniku");
                break;
            case ROUTER_HOME.Authors:
                goToStartSection("oautorach");
                break;
            case ROUTER_HOME.Preview:
                goToStartSection("podgląd");
                break;
            default:
                window.scrollTo({ top: 0, behavior: "smooth" })
        }
    }, [location.pathname])

    return (
        <>
            <Start />
            <About />
            <Authors />
            <Preview />
            <NavLink to={ROUTER_HOME.MainPage} className="przycisk">
                arrow_drop_up
            </NavLink>
        </>
    )
}

export default MainPage;