import React from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import About from "./areas/About";
import Authors from "./areas/Authors";
import BuyNow from "./areas/BuyNow";
import MainPage from "./areas/MainPage";
import Nav from "./areas/Nav";
import OrderDetails from "./areas/OrderDetails";
import Payment from "./areas/Payment";
import Preview from "./areas/Preview";
import Start from "./areas/Start";
import Summary from "./areas/Summary";

export const ROUTER_HOME = {
    MainPage: "/Start",
    About: "/OPoradniku",
    Authors: "/OAutorach",
    Preview: "/Podgląd",
    BuyNow: "/Zamowienie",
    OrderDetails: "/ZamowienieDetale",
    Payment: "/ZamowieniePlatnosc"
}

const App: React.FC = () => {
    return (
        <BrowserRouter>
            <div className="router-home">
                <Nav />
                <Switch>
                    <Route path={ROUTER_HOME.MainPage} component={MainPage} />
                    <Route path={ROUTER_HOME.About} component={MainPage} />
                    <Route path={ROUTER_HOME.Authors} component={MainPage} />
                    <Route path={ROUTER_HOME.Preview} component={MainPage} />
                    <Route path={ROUTER_HOME.BuyNow} component={BuyNow} />
                    <Route path={ROUTER_HOME.OrderDetails} component={OrderDetails} />
                    <Route path={ROUTER_HOME.Payment} component={Payment} />
                    <Redirect path="*" to={ROUTER_HOME.MainPage} />
                </Switch>
                {/* <Start />
                <About />
                <Authors />
                <Preview /> */}

                {/* <OrderDetails /> */}
                {/* <BuyNow /> */}
                {/* <Payment /> */}
            </div>
        </BrowserRouter>
    );
};

export default App;
